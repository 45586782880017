<template>
  <div>
    <router-link class="come-back" to="/admin/contacts/">
      <div></div>
      Вернуться назад
    </router-link>

    <h2 class="title_categories">
      {{ isEdit ? `Редактирование блока обратной связи №${$route.params.id}` : 'Создание блока обратной связи' }}
    </h2>
    <tricolor-line class="card_containet__tricolor"/>

    <form @submit.prevent="createContact" ref="createContact">
      <div class="inputs__container">
        <div class="content-sb">
          <p>Заголовок<span>*</span></p>
          <input required placeholder="Введите заголовок" v-model="contactData.title" type="text" name="title" class="input blue">
        </div>
        <div class="content-sb">
        <p>Заголовок (Англ)<span>*</span></p>
        <input placeholder="Введите заголовок (Англ)" v-model="contactData.title_eng" type="text" name="title_eng" class="input blue">
      </div>
        <div class="content-sb">
          <p>Приоритет<span>*</span></p>
          <input required placeholder="Введите приоритет" v-model="contactData.order" type="number" name="order" class="input blue">
        </div>
      </div>

      <div class="inputs__container">
        <div v-for="(contactBlock, index) in contactsBlocks" :key="`contactBlock${index}`" class="contacts__block">
          <header>
            <h6>
              Блок №{{ index+1 }}
            </h6>

            <img src="/assets/icons/close.svg" @click="deleteContactBlock(index)">
          </header>

          <div class="content-sb">
            <p>Положение<span>*</span></p>
            <select v-model="contactsBlocks[index].position" class="input blue">
              <option value="0">Слева</option>
              <option value="1">Справа</option>
            </select>
          </div>
          <div class="content-sb">
            <p>Лейбл<span>*</span></p>
            <input required placeholder="Введите заголовок" v-model="contactsBlocks[index].label" type="text" name="title" class="input blue">
          </div>
          <div class="content-sb">
            <p>Лейбл (Англ)<span>*</span></p>
            <input placeholder="Введите заголовок (Англ)" v-model="contactsBlocks[index].label_eng" type="text" name="label_eng" class="input blue">
          </div>
          <div class="content-sb">
            <p>Текст<span>*</span></p>
            <ckeditor :config="$editorConfig" :editor-url="$root.editorUrl" v-model="contactsBlocks[index].content" class="wysiwyg"/>
          </div>
          <div class="content-sb">
            <p>Текст (Англ)<span>*</span></p>
            <ckeditor :config="$editorConfigEng" :editor-url="$root.editorUrl" v-model="contactsBlocks[index].content_eng" class="wysiwyg"/>
          </div>
          <div class="content-sb">
            <p>Приоритет<span>*</span></p>
            <input required placeholder="Введите приоритет" v-model="contactsBlocks[index].order" type="number" name="order" class="input blue">
          </div>
        </div>

        <div class="btn-blue" @click="addBlock()">
          Добавить блок
        </div>
      </div>

      <footer class="content-sb btns-create-form">
        <router-link :to="$store.getters.getForMediaKey ? '/admin/for_media_contacts' : '/admin/contacts'" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'CreateContact',

  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      contactData: {
        title: null,
        title_eng: null,
        order: 1000
      },
      contactsBlocks: [

      ]
    }
  },

  watch: {
    '$store.getters.getCurrentContact' () {
      const dataFromServer = this.$store.getters.getCurrentContact

      this.contactData = {
        title: dataFromServer.title,
        title_eng: dataFromServer.title_eng,
        order: dataFromServer.order
      }

      this.contactsBlocks = dataFromServer.contacts_blocks.map((block) => {
        return {
          ...block,
          position: Number(block.position)
        }
      }).sort((a, b) => {
        return b.order - a.order
      })
    }
  },

  created () {
    if (this.isEdit) {
      this.$store.dispatch('getCurrentContact', this.$route.params.id)
    }
  },

  methods: {
    createContact () {
      const formData = new FormData()

      for (const key in this.contactData) {
        formData.append(key, this.contactData[key])
      }

      this.contactsBlocks.forEach((contactBlock, index) => {
        formData.append('contactsBlocks[]', JSON.stringify(contactBlock))
      })

      console.debug(...formData)

      if (!this.isEdit) {
        this.$store.dispatch('createContacts', formData)
      } else {
        formData.append('_method', 'PUT')
        this.$store.dispatch('editContacts', {
          data: formData,
          id: this.$route.params.id
        })
      }
    },

    addBlock () {
      this.contactsBlocks.push({
        position: 0,
        label: '',
        content: '',
        order: 1000
      })
    },

    deleteContactBlock (index) {
      this.contactsBlocks.splice(index, 1)
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  .wysiwyg {
    width: 33.75rem;
  }

  .contacts__block {
    border-bottom: 1px solid #D5D6D9;
    margin-bottom: 1rem;

    header {
      margin-bottom: .75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > h4 {
        font-size: .875rem;
        line-height: 1.375rem;
      }

      > img {
        width: 1.5rem;
        height: 1.5rem;
        object-fit: contain;
        object-position: center;
        cursor: pointer;
      }
    }
  }

  .btns-create-form {
    border-top: 1px solid #D5D6D9;
    padding-top: 2.5rem;
    margin-top: 2.5rem;
  }

  .content-sb {
    margin-bottom: 1.25rem;

    span {
      color: red;
    }
  }

  .select, .input {
    width: 33.75rem;
  }

  .inputs__container {
    margin-bottom: 2rem;
    border-bottom: 1px solid #D5D6D9;
  }

  .content-blocks__container {
    display: flex;
    flex-direction: column;

    > .content-block {
      margin-bottom: 1.875rem;

      header {
        margin-bottom: .75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h4 {
          font-size: .875rem;
          line-height: 1.375rem;
          color: $mainFontColor;
        }

        > img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
          cursor: pointer;
        }
      }

      .data-card {
        background: white;
        border: 1px solid #EBF3FF;
        box-sizing: border-box;
        box-shadow: 0 .9375rem 1.5625rem rgba(189, 205, 221, 0.15);
        border-radius: .5rem;
        padding: 1.25rem;
        width: 100%;
        min-height: 8.875rem;
      }
    }
  }

  .add-content-block__container {
    background-color: $neutral-placeholder;
    width: 100%;
    padding: 1.25rem;
    border-radius: .187rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: .5rem;

    &:after {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      background-color: $neutral-placeholder;
      top: 0;
      left: 50%;
      transform: translate(-50%,-50%) rotate(45deg);
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.25rem;
    }

    > .btn-blue > img {
      width: 1.125rem;
      height: 1.125rem;
      object-fit: contain;
      object-position: center;
      margin-right: .5rem;
    }
  }
</style>
